<template>
  <div class="addTopicCategory">
    <div style="padding: 40px 93px">
      <el-form ref="form" :model="formData" :rules="rules" class="categoryForm" label-width="100px">
        <el-form-item prop="categoryName" label="分类名称">
          <el-input
            v-model="formData.categoryName"
            maxlength="4"
            placeholder="请填写分类名称"
            show-word-limit
          ></el-input>
        </el-form-item>

        <el-form-item prop="state" label="分类状态">
          <el-radio v-model="formData.state" label="0">启用</el-radio>
          <el-radio v-model="formData.state" label="1">禁用</el-radio>
        </el-form-item>

        <el-form-item v-show="formData.state == 0" prop="sort" label="排序">
          <el-input-number
            v-model="formData.sort"
            :min="1"
            :max="listTotal + 1"
            :step="1"
            step-strictly
          ></el-input-number>
          <div class="tips">仅支持≥1的整数</div>
        </el-form-item>
      </el-form>
    </div>

    <div class="bottom">
      <el-button size="mini" @click="cancel">取消</el-button>
      <el-button size="mini" type="primary" @click="submit">确定</el-button>
    </div>
  </div>
</template>

<script>
import { addTopicCategory, updateTopicCategory, getTopicCategoryList } from '@/api/friendCircle.js'

export default {
  props: {
    editRecord: {
      type: Object,
      default: function () {
        return {}
      },
    },
    listTotal: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      formData: {
        categoryName: '',
        state: '0',
        sort: '',
      },

      rules: {
        categoryName: [{ required: true, message: '请填写分类名称', trigger: 'blur' }],
        state: [{ required: true, message: '请选择分类状态', trigger: 'blur' }],
        sort: [{ required: true, message: '请选择排序顺序', trigger: 'blur' }],
      },
      listLength: 1,
    }
  },
  watch: {},
  mounted() {
    if (Object.keys(this.editRecord).length === 0) {
      this.formData.sort = this.listTotal + 1
    } else {
      const { categoryName, sort, isDisable } = this.editRecord
      this.formData = {
        categoryName,
        sort,
        state: String(isDisable),
      }
    }
  },
  beforeDestroy() {
    Object.assign(this._data, this.$options.data())
    this.$emit('update:editRecord', {})
    this.$emit('close')
  },
  methods: {
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let reqObj = {}

          if (Object.keys(this.editRecord).length === 0) {
            reqObj = {
              data: {
                categoryName: this.formData.categoryName,
                isDisable: this.formData.state,
                sort: this.formData.sort,
              },
            }

            addTopicCategory(reqObj)
              .then((res) => {
                if (res.success) {
                  this.$message.success('保存成功')
                  this.$emit('close', 1)
                }
              })
              .catch((e) => {
                this.$message.error(e)
              })
          } else {
            reqObj = {
              data: {
                categoryName: this.formData.categoryName,
                isDisable: this.formData.state,
                sort: this.formData.sort,
                categoryId: this.editRecord.categoryId,
              },
            }

            updateTopicCategory(reqObj)
              .then((res) => {
                if (res.success) {
                  this.$message.success('保存成功')
                  this.$emit('close', 1)
                }
              })
              .catch((e) => {
                this.$message.error(e)
              })
          }
        } else {
        }
      })
    },

    cancel() {
      this.$emit('close')
    },

    getListLength() {
      const reqObj = {
        data: {
          searchKey: '',
        },
      }

      getTopicCategoryList(reqObj).then((res) => {
        if (res.success) {
          this.listLength = Number(res.data.total)
          if (Object.keys(this.editRecord).length === 0) {
            this.formData.sort = String(Number(res.data.total) + 1)
          }
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.addTopicCategory {
  .tips {
    color: #9a9d9e;
  }

  .bottom {
    padding: 12px 24px;
    text-align: right;
    border-top: 1px solid #ededed;
  }
}
</style>
